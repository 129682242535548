.ui.attached.segment {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: none;
}

.ui.tabular.menu {
  border: none;
}

.ui.tabular.menu .active.item {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: none;
  background-color: #ffffff;
}