body {
  background-color: #e9ebee !important;
}

body.pushable > .pusher,
body.pushable {
  background-color: #e9ebee !important;
}

body,
html {
  height: unset !important;
}

h1,
h2,
h3 {
  /*text-transform: uppercase;*/
  color: #3b5998;
}
.revokedHeader {
  background-color: #3b5998;
  color: white;
}
.revokedText {
  color: #dddddd;
}
.configHeader {
  background-color: #3b5998;
  color: white;
}

.ui.inverted.blue.menu,
.ui.inverted.menu .blue.active.item {
  background-color: #3b5998;
}

.container {
  width: 80%;
}

.navbar {
  height: 37px;
}

#ohsSidebar {
  z-index: 10000;
}

/* only work for mac system */
.mac-os .ui.menu:not(.vertical) .menu.right {
  margin-top: -7px;
}

.search-header {
  padding: 0 10px 0 0 !important;
}

a {
  color: #009eec;
  text-decoration: none;
}

.ui.inverted.menu .active.red.item {
  background-color: #db2828 !important;
}

.ui.inverted.menu .green.item {
  background-color: rgb(0, 128, 0) !important;
}

.not-active {
  pointer-events: none;
  cursor: default;
}

.disabledTierObject {
  color: #a0a0a0;
}

.movingHandler {
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: middle;
  cursor: move;
}

.stickyFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.pusher {
  min-height: 100% !important;
  height: 100% !important;
  padding-top: 20px !important;
  padding-bottom: 100px !important;
  overflow: visible !important;
}
/*
body.pushable {
    overflow: visible !important;
} */

#blockUI {
  z-index: 9999;
  /*avoid top bar*/
  margin-top: 40px;
}

.ui.menu .item > i.icon {
  opacity: 1 !important;
}

.ui.inverted.menu .item,
.ui.inverted.menu .item > a:not(.ui) {
  background: 0 0;
  color: rgba(255, 255, 255);
}

a.ng-binding,
a[ng-click] {
  cursor: pointer;
}

a.ng-binding.ui.disabled,
span.ng-binding.ui.disabled {
  color: darkgrey;
}

/* upload */
.thumb {
  width: 24px;
  height: 24px;
  float: none;
  position: relative;
  top: 7px;
}

form .progress {
  line-height: 15px;
}

.progress {
  display: inline-block;
  width: 100px;
  border: 3px groove #ccc;
}

.progress div {
  font-size: smaller;
  background: orange;
  width: 0;
}

.ui.table.primary thead th {
  background-color: #3b5998 !important;
  color: #fff !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
  border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
}

.ui.table.primary thead th a,
.ui.table.primary thead th a:hover,
.ui.table.primary thead i {
  color: #fff !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui .table td {
  padding: 0.65em;
}

.ui.buttons .button {
  margin-right: 1px;
}

.ui.modal > .close {
  top: 1.0535rem;
  right: 1rem;
  color: rgba(0, 0, 0, 0.87);
}

th.ng-hide,
tr.ng-hide {
  visibility: hidden;
  height: 0px !important;
}

#task-upcoming {
  color: orange !important;
}

#task-due {
  color: #21ba45 !important;
}

#task-overdue {
  color: #db2828 !important;
}

.gauge {
  margin: 0px !important;
  text-align: center;
}

.gauge g.arc {
  fill: steelblue;
}

.gauge g.pointer {
  fill: #e85116;
  stroke: #b64011;
}

.gauge g.label text {
  text-anchor: middle;
  font-size: 13px;
  font-weight: bold;
  fill: #666;
}

.ui.large.modal > .header,
.ui.modal > .header:not(.ui) {
  font-size: 1.2em;
  background-color: #3b5998;
  color: white;
  padding: 0.8rem 1rem;
}

.ui.modal > .close {
  top: 0.3em;
  color: rgba(255, 255, 255, 0.9);
}

tr.collapsible {
  cursor: pointer;
}

.ui.dark.green.button {
  background-color: #1e6e42 !important;
}

.ui.dark.green.button:hover {
  background-color: #035629 !important;
}

.ui.dark.teal.button {
  background-color: #036d68 !important;
}

.ui.dark.teal.button:hover {
  background-color: #037b77 !important;
}

/* widget */
.widget {
  min-height: 180px;
  max-height: 180px;
  overflow: hidden;
}

.widget-header {
  background: #3b5998 !important;
  color: #ffffff !important;
  border-color: #3b5998 !important;
}

.widget-header a {
  color: rgba(234, 234, 234, 0.87) !important;
}

.widget-header a:hover {
  color: #ffffff !important;
}

.widget .content {
  font-size: 0.9em;
}

.widget-header {
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui.attached.segment.widget {
  border: 1px solid #babdc3;
}

.twitter-item {
  margin-top: 5px;
}

.twitter.content {
  min-width: 240px;
  position: absolute;
  padding-right: 10px;
}

.twitter > .description {
  margin-top: 5px;
  margin-bottom: 5px;
}

/*.dashboard .column {
    min-width: 340px;
}*/

.twitter > .description > .status,
.twitter > .description > p {
  padding-right: 10px;
  margin-bottom: 5px;
}

.twitter > .description > .status .icon {
  font-size: 0.8em;
}

/* training history */
.training-history-info {
  display: block;
  min-width: 320px;
}

.header .meta {
  color: rgba(0, 0, 0, 0.4);
}

.more-actions {
  width: 310px;
  min-width: 310px;
}

.few-actions {
  width: 210px;
  min-width: 210px;
}

.meta a {
  font-size: 0.8em;
}

.ui.massive.button {
  font-size: 1.45rem;
  text-align: left;
}

.ui.labeled.icon.button {
  padding-left: 3.2em !important;
}

img.sc-logo-mobile {
  width: 124px !important;
  /*margin-bottom: -20px !important;*/
}

.ui.striped.segments .segment:nth-child(2n) {
  background-color: rgba(0, 0, 50, 0.02);
}

h1 > .ui.buttons,
h2 .ui.buttons,
h3 > .ui.buttons,
h4 > .ui.buttons {
  float: right !important;
}

span.ui.meta.ng-binding {
  opacity: 0.8;
  color: gray;
  font-size: 0.9em;
}

.ui.tabular.menu .active.item,
.ui.tabular.menu .item {
  cursor: pointer;
}
.ui.form .fields {
  margin-bottom: 0 !important;
}

.ui.form .fields .field {
  margin-bottom: 1em;
}

#login-form {
  margin-top: 160px;
}

.ui.modal > .content {
  overflow-y: auto;
  overflow-x: hidden;
}

.ui.custom-message {
  position: relative;
  min-height: 1em;
  margin: 1em 0;
  background: #f8f8f9;
  padding: 1em 1.5em;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease,
    box-shadow 0.1s ease;
  transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease,
    box-shadow 0.1s ease;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent;
}

.ui.custom-message > :first-child {
  margin-top: 0;
}

.ui.custom-message > :last-child {
  margin-bottom: 0;
}

.ui.error.custom-message {
  background-color: #fff6f6;
  color: #9f3a38;
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
}

.document .ui.comments .comment .avatar {
  width: 2em;
}

.document .line {
  position: absolute;
  left: 35px;
  border-left: 1px solid #f3f4f5;
  height: 460px;
}

@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable) tr {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  #login-form {
    margin-top: 80px;
  }
}

@media only screen and (max-device-width: 480px) {
  h1 > .ui.buttons,
  h2 .ui.buttons,
  h3 > .ui.buttons,
  h4 > .ui.buttons {
    float: none !important;
    display: block;
    margin-bottom: 10px;
  }

  #login-form {
    margin-top: 80px;
  }
}

.nsw-message {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

/****** table transfers to form in AI, Document and Action module */
.ui.form .eight.wide.field.element {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.87) inset;
  background: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: left;
  padding-top: 0.8em;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  border-left: 1px solid rgba(34, 36, 38, 0.1);
}

.ui.form .eight.wide.field.element > b {
  padding-left: 4px;
}

.ui.form .eleven.wide.field.element {
  padding: 0.35em;
  background: white;
  margin: 0;
  text-align: left;
  border-right: 1px solid rgba(34, 36, 38, 0.1);
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  border-top: 1px solid rgba(34, 36, 38, 0.1);
}

.leftbutton {
  margin-top: 13px;
}

.rightbutton {
  float: right;
  margin-top: 13px;
}

/****** table transfers to form in Contractor module */
#insurance {
  background: #f9fafb;
  border-top: 0.2em solid #767676;
  padding: 0.4em;
}

#insurance_attachment {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 0.6em;
}

.insurance_outer {
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
  border-radius: 0.28571429rem;
}

#insurance_content_1 {
  padding: 0.4em;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}

#insurance_content_1:hover {
  background: #f3f3f3;
}

#insurance_content_2,
#insurance_content_2:hover {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}

#insurance_content_2:nth-child(odd) {
  background: #f9fafb;
}

.ui.form .four.wide.field.pl {
  margin-bottom: 0.4em;
  padding-top: 0.6em;
  width: 23% !important;
}

.ui.form .three.wide.field.plN {
  margin-bottom: 0.4em;
  padding-top: 0.6em;
}

.ui.form .three.wide.field.date {
  margin-bottom: 0.4em;
  padding-top: 0.6em;
  width: 19% !important;
}

.ui.form .three.wide.field.attachment {
  margin-bottom: 0.4em;
  padding-top: 0.6em;
  width: 19% !important;
}

.ui.form .four.wide.field.buttons {
  margin-bottom: 0.4em;
  padding-top: 0.6em;
  width: 21% !important;
}

.insurance_text_position {
  /*padding-top: 0.5em;*/
}

@media only screen and (max-width: 781px) {
  .ui .form .four.fields > .field {
    padding-right: 0em;
  }
}

@media only screen and (max-width: 767px) {
  .ui.form .four.wide.field.pl {
    width: 100% !important;
  }

  .ui.form .three.wide.field.plN {
    width: 100% !important;
  }

  .ui.form .three.wide.field.date {
    width: 100% !important;
  }

  .ui.form .three.wide.field.attachment {
    width: 100% !important;
  }

  .ui.form .four.wide.field.buttons {
    width: 100% !important;
  }

  .ui.form .eleven.wide.field.element {
    border-left: 1px solid rgba(34, 36, 38, 0.1);
  }
}

/*** css setting for tooltips ***/

.ui.popup.very.wide {
  overflow: hidden;
}

/**** table transfer to div for chemical module ****/

.ui.form .five.wide.field.element {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.87) inset;
  background: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: left;
  padding-top: 0.8em;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.ui.form .five.wide.field.element > b {
  padding-left: 4px;
}

.ui.blue h2 {
  color: white;
}

.modals.dimmer .ui.scrolling.modal {
  position: relative !important;
  top: auto !important;
  left: auto;
}

/* CSS Settings for action elements
** TODO - remove this site css file and replace with proper build automation
*/

.editAlign {
  margin-top: -5px;
  float: right;
}

.editAlign #floating-box {
  position: absolute;
  min-width: 100px;
}

#floating-box {
  text-align: left;
  margin: 0.5rem 0;
  z-index: 10000;
}

#btnAssign {
  margin-bottom: 0.2rem;
}
@media screen {
  #printLogo {
    display: none;
  }
}
.print-ready {
  max-width: 900px;
  margin: auto;
}
@media print {
  @page {
    margin: 0.5cm;
  }

  #printLogo {
    display: block;
    text-align: center;
  }
  .ui.top.attached.tabular.menu {
    display: none;
  }

  .ui.navbar {
    display: none;
  }
  .search-header {
    display: none;
  }
  .ui.right.floated.icon.compact.primary.button {
    display: none;
  }

  .ui.attached.segment {
    border: none;
    display: block;
    padding: 0px;
    page-break-inside: avoid;
  }

  .noprint {
    display: none !important;
  }

  .ui.buttons {
    display: none !important;
  }
  .ui.button {
    display: none !important;
  }

  .print-ready h2 {
    margin-top: 10px;
  }

  .ui.grid.printable > .row > [class*="six wide"].column,
  .ui.grid.printable > .column.row > [class*="six wide"].column,
  .ui.grid.printable > [class*="six wide"].column,
  .ui.column.grid.printable > [class*="six wide"].column {
    width: 100% !important;
    margin-top: 50px;
    page-break-inside: avoid;
  }

  .ui.grid.printable > .row > [class*="eight wide"].column,
  .ui.grid.printable > .column.row > [class*="eight wide"].column,
  .ui.grid.printable > [class*="eight wide"].column,
  .ui.column.grid.printable > [class*="eight wide"].column {
    width: 100% !important;
    margin-top: 10px;
    page-break-inside: avoid;
  }

  .ui.grid.printable > .row > [class*="ten wide"].column,
  .ui.grid.printable > .column.row > [class*="ten wide"].column,
  .ui.grid.printable > [class*="ten wide"].column,
  .ui.column.grid.printable > [class*="ten wide"].column {
    width: 100% !important;
    margin-top: 50px;
  }

  canvas {
    margin: 0 auto;

    page-break-inside: avoid;
  }
}

.white-text {
  color: white;
}
.primary-background {
  background-color: #415a93 !important;
}
.full-width {
  width: 100%;
}
.imgPreview {
  border: 2px solid gray;
  border-radius: 3px;
}
.absolute-centered {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.vertical-centered {
  display: flex !important;
  align-items: center !important;
}

input[type="time"]::-ms-clear {
  display: none;
}
input[type="time"]::-webkit-clear-button {
  display: none;
}

.div-looks-like-dropdown {
  cursor: pointer;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  outline: 0;
  min-width: 14em;
  min-height: 2.7142em;
  background: #fff;
  display: inline-block;
  padding: 0.78571429em 2.6em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, width 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease;
}
.action-color-red {
  background-color: #ec0000 !important;
  border-color: #ec0000 !important;
}
.action-color-red-orange {
  background-color: #ff6b1c !important;
  border-color: #ff6b1c !important;
}
.action-color-orange {
  background-color: #fbb309 !important;
  border-color: #fbb309 !important;
}
.action-color-yellow {
  background-color: #ffe001 !important;
  border-color: #ffe001 !important;
}
.action-color-light-green {
  background-color: #00c545 !important;
  border-color: #00c545 !important;
}
.action-color-blue {
  background-color: #0081d3 !important;
  border-color: #0081d3 !important;
}
.action-color-teal {
  background-color: #00bbaf !important;
  border-color: #00bbaf !important;
}
.action-color-green {
  background-color: #007443 !important;
  border-color: #007443 !important;
}
.action-color-grey {
  background-color: #e0e1e2;
  border-color: #e0e1e2;
}
/*
use to expand controller to 100% of width;
 */
/*#controllerRootEleId> div {*/
/*  width: 100%;*/
/*}*/
